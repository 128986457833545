<template>
  <div>
    <!-- Buy + Links -->
    <template
      v-if="
        plugin.editions.length > 1 ||
        (plugin.editions.length === 1 && !isPluginFree(plugin))
      "
    >
      <plugin-meta-buy-button :plugin="plugin" />
    </template>

    <ul class="list-reset space-y-2">
      <template v-if="plugin.documentationUrl">
        <li>
          <c-btn
            block
            target="_blank"
            :href="plugin.documentationUrl"
            :title="plugin.name + ' Documentation'"
          >
            <div>
              <c-icon class="mr-2" icon="book" />
              Documentation

              <svg
                class="inline-block w-3 text-grey ml-1"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </div>
          </c-btn>
        </li>
      </template>
      <li>
        <c-btn
          block
          class="flex items-center"
          target="_blank"
          :href="plugin.repository"
          :title="plugin.name + ' Repository'"
        >
          <c-icon class="mr-2" icon="github" set="solid" />
          Repository

          <svg
            class="inline-block w-3 text-grey ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </c-btn>
      </li>
    </ul>

    <!-- Meta data -->
    <div class="mt-2">
      <install-plugin :plugin="plugin" />
      <PluginRatingStat
        :handle="plugin.handle"
        :stats="plugin.reviewStats"
      />

      <stat>
        <template #title>
          <template v-if="activeInstallsDate !== null">
            {{ $luxon(activeInstallsDate) }}
          </template>
          <template v-else> Active Installs </template>
        </template>
        <template #content>
          <div class="flex">
            <div class="w-1/3">
              {{ $filters.formatNumber(activeInstalls) }}
            </div>

            <plugin-installs-chart
              class="flex-1"
              :plugin="plugin"
              @update-current-data-point="updateActiveInstallsDataPoint"
            />
          </div>
        </template>
      </stat>
      <div class="grid grid-cols-2">
        <stat class="meta-version">
          <template #title> Version </template>
          <template #content>
            {{ plugin.version }}
          </template>
        </stat>
        <stat>
          <template #title> License </template>
          <template #content>
            {{ licenseLabel }}
          </template>
        </stat>
      </div>

      <div>
        <!-- Compatibility -->

        <stat class="meta-compatibility">
          <template #title> Compatibility </template>
          <template #content>
            <div class="mt-2 space-y-2">
              <div class="flex items-center gap-2">
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="w-5 h-5 opacity-70 flex items-center"
                  v-html="craftMaskIcon"
                />
                <!-- eslint-enable vue/no-v-html -->
                <div>
                  {{ plugin.compatibility }}
                </div>
              </div>

              <template v-if="plugin.cloudTested">
                <div class="flex items-center gap-2">
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="w-5 h-5 opacity-70 flex items-center"
                    v-html="cloudIcon"
                  />
                  <!-- eslint-enable vue/no-v-html -->
                  <div>Tested on Cloud</div>
                </div>
              </template>

              <template v-if="plugin.supportsGql">
                <div class="flex items-center gap-2">
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="w-5 h-5 opacity-70 flex items-center"
                    v-html="graphqlIcon"
                  />
                  <!-- eslint-enable vue/no-v-html -->
                  <div>Supports GraphQL</div>
                </div>
              </template>
            </div>
          </template>
        </stat>
      </div>

      <stat>
        <template #title> Last release </template>
        <template #content>
          {{ $luxon(plugin.lastUpdate) }}
        </template>
      </stat>

      <stat class="meta-categories">
        <template #title> Categories </template>
        <template #content>
          <ul v-if="pluginCategories.length > 0">
            <template v-for="(category, key) in pluginCategories" :key="key">
              <li class="inline-block mr-2 my-1.5">
                <c-link
                  :key="key"
                  class="px-4 py-1.5 text-xs font-medium rounded-full"
                  :class="{
                    'bg-blue-50 hover:bg-blue-600 dark:bg-blue-600/40': true,
                    'hover:text-white dark:text-blue-300': true,
                  }"
                  :to="'/categories/' + category.slug"
                  :title="category.title + ' plugins for Craft CMS'"
                >
                  {{ category.title }}
                </c-link>
              </li>
            </template>
          </ul>
        </template>
      </stat>

      <github-activity-stat :plugin="plugin" />
    </div>

    <!-- Report an issue -->
    <ul class="list-reset space-y-2 mt-8">
      <li>
        <c-btn
          kind="danger"
          :href="
            'mailto:issues@craftcms.com?subject=' +
            encodeURIComponent('Issue with ' + plugin.name) +
            '&body=' +
            encodeURIComponent(
              'I would like to report the following issue with ' +
                plugin.name +
                ' (https://plugins.craftcms.com/' +
                plugin.handle +
                '):\n\n',
            )
          "
        >
          <c-icon class="mr-1" icon="flag" set="solid" />
          Report plugin
        </c-btn>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import craftMaskIcon from '../assets/images/craft-mask.svg?raw';
  import graphqlIcon from '../assets/images/graphql.svg?raw';
  import cloudIcon from '../assets/images/cloud.svg?raw';

  import PluginInstallsChart from './PluginInstallsChart.vue';
  import Stat from './Stat.vue';
  import GithubActivityStat from './GithubActivityStat.vue';
  import InstallPlugin from './InstallPlugin.vue';
  import PluginMetaBuyButton from './PluginMetaBuyButton.vue';
  import {useHelpers} from '~/composables/useHelpers';
  import {computed, ref, toRefs} from '#imports';
  import type {CraftPlugin} from '~';
  import PluginRatingStat from '~/components/PluginRatingStat.vue';

  const {isPluginFree} = useHelpers();

  const props = withDefaults(
    defineProps<{
      plugin: CraftPlugin;
      licenseLabel?: string;
      pluginCategories: any[];
    }>(),
    {
      licenseLabel: '',
    },
  );

  const {plugin, licenseLabel, pluginCategories} = toRefs(props);

  const activeInstallsValue = ref<number | null>(null);
  const activeInstallsDate = ref<string | null>(null);

  const activeInstalls = computed(() => {
    if (activeInstallsValue.value !== null) {
      return activeInstallsValue.value;
    }

    return plugin.value.activeInstalls;
  });

  const updateActiveInstallsDataPoint = (dataPoint: {
    value: number | null;
    date: string | null;
  }) => {
    if (dataPoint) {
      activeInstallsValue.value = dataPoint.value;
      activeInstallsDate.value = dataPoint.date;
    } else {
      activeInstallsValue.value = null;
      activeInstallsDate.value = null;
    }
  };
</script>
