<script setup lang="ts">
  import Stat from '~/components/Stat.vue';
  import type {ReviewStats} from '~';
  import {useEnv} from '~/helpers/env';

  const props = defineProps<{
    stats: ReviewStats | null;
    handle: string;
  }>();

  const {getPluginReviewUrl} = useEnv();
</script>

<template>
  <template v-if="stats">
    <Stat>
      <template #title>
        <div class="flex items-baseline justify-between">
          {{
            stats.totalReviews === 1
              ? `${stats.totalReviews} Review`
              : `${stats.totalReviews} Reviews`
          }}
          <router-link
            v-if="stats.totalReviews > 0"
            :to="`${handle}/reviews`"
            class="text-xs"
          >
            All reviews
          </router-link>
        </div>
      </template>
      <template #content>
        <template v-if="stats.totalReviews > 0">
          <div class="flex items-center gap-3 mt-2">
            <div class="flex items-baseline gap-1 relative">
              <span class="text-3xl font-normal">{{ stats.ratingAvg }}</span>
              <span class="text-sm text-gray-500 dark:text-gray-400">/ 5</span>
            </div>
            <div class="flex items-center gap-1">
              <RatingStars size="lg" :rating="stats.ratingAvg" />
            </div>
          </div>
          <div class="flex items-baseline text-sm mt-4 gap-4 text-gray-300">
            <a :href="getPluginReviewUrl(props.handle)">Leave a review</a>
          </div>
        </template>
      </template>
    </Stat>
  </template>
  <template v-else>
    <Stat>
      <template #title>Reviews</template>
      <template #content>
        <p class="font-normal">This plugin doesn't have any reviews.</p>

        <div class="mt-2">
          <c-btn block target="_blank" :href="getPluginReviewUrl(props.handle)">
            Leave a review
          </c-btn>
        </div>
      </template>
    </Stat>
  </template>
</template>

<style scoped lang="scss"></style>
