<template>
  <div
    :class="{
      'border-b dark:border-gray-700 py-4': border,
    }"
  >
    <div class="text-gray-500 dark:text-gray-400">
      <slot name="title" />
    </div>
    <div class="mt-1 font-medium">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StatComponent',
    props: {
      border: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
