<script setup lang="ts">
  import {computed} from 'vue';
  import {type Plugin} from '~/stores/plugin-store';
  import AlertIcon from '@/assets/images/alert.svg?raw';

  const props = defineProps<{
    plugin: Plugin;
  }>();

  const normalizeHeading = (heading: string) => {
    if (!heading) {
      return '';
    }

    // Normalize a period at the end of the heading.
    if (heading.endsWith('.')) {
      return heading;
    }

    return `${heading}.`;
  };

  const defaultHeading = 'This plugin is no longer maintained.';
  const heading = computed(() => {
    return normalizeHeading(props.plugin.note) || defaultHeading;
  });
</script>

<template>
  <div class="pl-3 p-2 rounded border border-solid border-red-400 text-red-700">
    <div class="flex gap-2 items-center flex-nowrap">
      <!-- eslint-disable vue/no-v-html -->
      <div class="w-4 h-4" v-html="AlertIcon" />
      <!-- eslint-enable vue/no-v-html -->

      <div class="flex-1 mb-0">
        <strong>{{ heading }}</strong>
        <template v-if="plugin.replacementHandle">
          The developer recommends using
          <c-link :to="'/' + plugin.replacementHandle" :title="plugin.name">
            {{ plugin.replacementName }}
          </c-link>
          instead.
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
