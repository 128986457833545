<template>
  <div
    class="overflow-x-auto w-full flex gap-6 snap-x snap-mandatory pb-8"
    tabindex="0"
  >
    <a
      v-for="(image, key) in thumbnails"
      :key="key"
      class="shrink-0 snap-start w-80 aspect-[4/3]"
      @click.prevent="zoomImage(key)"
    >
      <div class="flex justify-center items-center w-full h-full">
        <img
          :alt="
            'Plugin screenshot thumbnail ' + (key + 1) + '/' + thumbnails.length
          "
          class="rounded-md max-w-full max-h-full"
          :src="image"
        />
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
  import {toRefs, useAppStore} from '#imports';

  const props = defineProps<{
    thumbnails: any[];
    images: any[];
  }>();

  const {thumbnails, images} = toRefs(props);

  const appStore = useAppStore();

  const zoomImage = (key) => {
    appStore.updateScreenshotModalImages(images.value);
    appStore.updateShowingScreenshotModal(true);
    appStore.updateScreenshotModalImageKey(key);
  };
</script>
