<template>
  <stat>
    <template #title> Installation Instructions </template>
    <template #content>
      <!-- Install Modes -->
      <div>
        <ul class="flex space-x-4 text-sm">
          <li
            v-for="(installMode, installModeKey) in installModes"
            :key="installModeKey"
            class="py-1"
          >
            <button
              :class="{
                'text-gray-700 dark:text-gray-400': !(
                  installMode.handle === currentInstallModeHandle
                ),
                'font-medium text-black dark:text-white border-b-2 border-orange-500':
                  installMode.handle === currentInstallModeHandle,
              }"
              @click="changeInstallMode(installMode.handle)"
            >
              {{ installMode.name }}
            </button>
          </li>
        </ul>
      </div>

      <!-- Installation instructions -->
      <div class="copy-package">
        <div class="mt-2 flex">
          <label class="sr-only" for="installation-instructions"
            >Installation instructions</label
          >
          <c-textbox
            id="installation-instructions"
            ref="input"
            class="w-full flex rounded-r-none font-mono focus:relative focus:z-10 text-sm"
            readonly="readonly"
            type="text"
            :value="currentInstallMode.copyValue"
            @focus="select"
          />
          <c-btn
            class="-ml-px w-14 rounded-l-none"
            :class="{
              'border-green-500 hover:border-green-500 active:border-green-500':
                showSuccess,
            }"
            :disable-shadow="true"
            :title="
              'Copy ' + currentInstallMode.name + ' instructions to clipboard'
            "
            @click="copy"
          >
            <template v-if="showSuccess">
              <c-icon class="text-green-500" icon="check" />
            </template>
            <template v-else>
              <c-icon
                class="text-black dark:text-white"
                icon="clipboard-copy"
              />
            </template>
          </c-btn>
        </div>

        <div class="mt-4 text-sm text-gray-500 dark:text-gray-400">
          <p>
            To install this plugin, copy the command above to your terminal.
          </p>
        </div>
      </div>
    </template>
  </stat>
</template>

<script>
  import Stat from './Stat';
  export default {
    components: {
      Stat,
    },
    props: {
      plugin: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        copyTimeout: null,
        showSuccess: false,
        currentInstallModeHandle: 'shell',
      };
    },

    computed: {
      currentInstallMode() {
        return this.installModes.find(
          (mode) => mode.handle === this.currentInstallModeHandle,
        );
      },
      installModes() {
        return [
          {
            name: 'Shell',
            handle: 'shell',
            copyValue: `composer require "${this.plugin.packageName}:^${this.plugin.version}" -w && php craft plugin/install ${this.plugin.handle}`,
          },
          {
            name: 'DDEV',
            handle: 'ddev',
            copyValue: `ddev composer require "${this.plugin.packageName}:^${this.plugin.version}" -w && ddev craft plugin/install ${this.plugin.handle}`,
          }
        ];
      },
    },

    methods: {
      select() {
        this.$refs.input.$el.select();
      },

      copy() {
        if (this.showSuccess) {
          return;
        }

        this.select();

        window.document.execCommand('copy');

        this.showSuccess = true;

        this.copyTimeout = setTimeout(() => {
          this.showSuccess = false;
        }, 3000);
      },

      changeInstallMode(installMode) {
        clearTimeout(this.copyTimeout);
        this.showSuccess = false;

        this.currentInstallModeHandle = installMode;
      },
    },
  };
</script>
