<template>
  <div class="p-4 text-center">
    <div class="inline-block">
      <div class="flex items-center">
        <div class="mr-1.5">
          <slot name="icon" />
        </div>
        <div>
          <slot name="count" />
        </div>
      </div>
    </div>
    <div class="mt-1 text-xs text-gray-500 dark:text-gray-400">
      <slot name="title" />
    </div>
  </div>
</template>
