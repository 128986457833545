<template>
  <div
    :class="{
      'space-y-2 mb-2': isPluginFree(plugin),
      'space-y-4 mb-4': !isPluginFree(plugin),
    }"
  >
    <template v-for="(edition, key) in plugin.editions" :key="key">
      <div>
        <plugin-edition :plugin="plugin" :edition="edition" context="meta" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import PluginEdition from './PluginEdition.vue';
  import {useHelpers} from '~/composables/useHelpers';
  import {toRefs} from '#imports';

  const {isPluginFree} = useHelpers();

  const props = defineProps<{
    plugin: object;
  }>();

  const {plugin} = toRefs(props);
</script>
