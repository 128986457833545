<template>
  <div v-if="plugin.installHistory">
    <ClientOnly>
      <div>
        <apexchart
          class="overflow-hidden"
          type="area"
          height="40"
          :options="chartOptions"
          :series="series"
          @mouse-leave="onMouseLeave"
          @mouse-move="onMouseMove"
          @mounted="onChartMounted"
        />
        <div
          v-if="chartMounted"
          class="mr-[0.6px] h-3 bg-gradient-to-b from-blue-100/100 to-blue-100/0 dark:from-blue-900/100 dark:to-blue-900/0"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script>
  export default {
    props: {
      plugin: {
        type: Object,
        required: true,
      },
    },

    emits: ['updateCurrentDataPoint'],

    data() {
      return {
        chartMounted: false,
      };
    },

    computed: {
      chartOptions() {
        return {
          fill: {
            opacity: 1,
            type: 'solid',
            colors: ['var(--chart-fill-color)'],
            gradient: null,
          },

          chart: {
            animations: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
            zoom: {
              enabled: false,
            },
            type: 'area',
            offsetY: 2,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            type: 'datetime',
            crosshairs: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: true,
            intersect: false,
            shared: true,
            marker: {
              show: false,
            },
            x: {
              format: 'dd/MM/yy',
            },
            custom() {
              return '';
            },
          },
        };
      },

      series() {
        return [
          {
            name: 'Active Installs',
            data: this.chartData,
          },
        ];
      },

      chartData() {
        const data = [];

        this.plugin.installHistory.forEach((item) => {
          data.push({
            x: new Date(item.date),
            y: item.activeInstalls,
          });
        });

        return data;
      },
    },

    methods: {
      onChartMounted() {
        this.chartMounted = true;
      },
      onMouseLeave() {
        this.$emit('updateCurrentDataPoint', null);
      },

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onMouseMove(event, chartContext, config) {
        let value = null;
        let date = null;

        if (
          config.globals.series &&
          config.globals.series[0] &&
          config.globals.series[0][config.dataPointIndex]
        ) {
          value = config.globals.series[0][config.dataPointIndex];
        }

        if (
          config.globals.seriesX &&
          config.globals.seriesX[0] &&
          config.globals.seriesX[0][config.dataPointIndex]
        ) {
          date = new Date(
            config.globals.seriesX[0][config.dataPointIndex],
          ).toISOString();
        }

        this.$emit('updateCurrentDataPoint', {
          value,
          date,
        });
      },
    },
  };
</script>

<style>
  body {
    --chart-fill-color: #dbeafe;
  }

  @media (prefers-color-scheme: dark) {
    body {
      --chart-fill-color: #1e3a8a;
    }
  }

  .apexcharts-tooltip {
    border: 0 !important;
  }
</style>
