<template>
  <plugin-layout>
    <template v-if="plugin">
      <div class="plugin-details-body leading-normal">
        <div class="plugin-description">
          <!-- Abandoned notice -->
          <template v-if="plugin.abandoned">
            <AbandonedAlert :plugin="plugin" class="mb-6" />
          </template>

          <!-- Screenshots -->
          <template v-if="plugin.thumbnailUrls.length">
            <div class="border-b dark:border-gray-700 mb-8">
              <plugin-screenshots
                :thumbnails="plugin.thumbnailUrls"
                :images="plugin.screenshotUrls"
              />
            </div>
          </template>

          <!-- Plugin details -->
          <div class="lg:flex">
            <div
              id="plugin-description-text"
              class="lg:flex-1 lg:pr-8 lg:mr-4 min-w-0"
            >
              <!-- eslint-disable vue/no-v-html -->
              <prose-block>
                <template v-if="longDescription">
                  <div v-html="longDescription" />
                </template>
                <template v-else-if="plugin.shortDescription">
                  <div v-html="plugin.shortDescription" />
                </template>
                <template v-else>
                  <div>
                    <p>No description.</p>
                  </div>
                </template>
              </prose-block>
              <!-- eslint-enable vue/no-v-html -->
            </div>
            <div class="lg:ml-4 lg:w-80 mt-8 lg:mt-0">
              <!-- Plugin meta -->
              <plugin-meta
                :plugin="plugin"
                :license-label="licenseLabel"
                :plugin-categories="pluginCategories"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <c-spinner />
    </template>
  </plugin-layout>
</template>

<script setup lang="ts">
  import {onBeforeRouteLeave, useRoute} from 'vue-router';
  import sanitizeHtml from 'sanitize-html';
  import PluginLayout from '../../components/PluginLayout.vue';
  import PluginScreenshots from '../../components/PluginScreenshots.vue';
  import PluginMeta from '../../components/PluginMeta.vue';
  import ProseBlock from '../../components/ProseBlock.vue';
  import {useEnv} from '~/helpers/env';
  import {
    computed,
    definePageMeta,
    useAsyncData,
    useHead,
    useAppStore,
    usePluginStoreStore,
  } from '#imports';

  definePageMeta({
    layout: 'site',
  });

  const route = useRoute();
  const appStore = useAppStore();
  const pluginStoreStore = usePluginStoreStore();
  const {appUrl} = useEnv();

  // Computed
  const pageMeta = computed(() => appStore.pageMeta);
  const categories = computed(() => pluginStoreStore.categories);
  const plugin = computed(() => pluginStoreStore.plugin);
  const showingScreenshotModal = computed(
    () => appStore.showingScreenshotModal,
  );

  const licenseLabel = computed(() => {
    switch (plugin.value?.license) {
      case 'apache-2.0': {
        return 'Apache-2.0';
      }
      case 'craft':
        return 'Craft';
      case 'gpl-2.0':
        return 'GPL-2.0';
      case 'gpl-3.0':
        return 'GPL-3.0';
      case 'mit':
        return 'MIT';
    }

    return plugin.value?.license;
  });

  const longDescription = computed(() => {
    return plugin.value?.longDescription;
  });

  const pluginCategories = computed(() => {
    return categories.value.filter((c) => {
      return plugin.value?.categoryIds.find((pc) => pc === c.id);
    });
  });

  // Fetch data
  await useAsyncData(async () => {
    if (
      pluginStoreStore.plugin &&
      pluginStoreStore.plugin.handle === route.params.handle &&
      pluginStoreStore.pluginDetailsCmsConstraint === appStore.cmsConstraint
    ) {
      return null;
    }

    await pluginStoreStore
      .getPluginDetailsByHandle(route.params.handle as string)
      .then(() => {
        const plugin = pluginStoreStore.plugin;

        if (plugin) {
          appStore.updatePageMeta({
            title: plugin.name,
            description: plugin.shortDescription,
          });
        }
      })
      .catch((errorMsg) => {
        if (errorMsg === "Couldn't find plugin") {
          throw createError({statusCode: 404, message: errorMsg});
        } else {
          throw createError({message: errorMsg});
        }
      });
  });

  // Head

  if (pageMeta.value && plugin.value) {
    let pluginUrl = 'https://plugins.craftcms.com';

    if (process.env.devHost) {
      pluginUrl =
        'http://' +
        process.env.devHost +
        (process.env.devPort ? ':' + process.env.devPort : '');
    }

    pluginUrl += route.fullPath;

    const data = {
      name: sanitizeHtml(plugin.value.name).replace(/"/g, '\\"'),
      description: sanitizeHtml(plugin.value.shortDescription).replace(
        /"/g,
        '\\"',
      ),
      imageUrl:
        plugin.value.screenshotUrls && plugin.value.screenshotUrls.length > 0
          ? plugin.value.screenshotUrls[0]
          : null,
      url: pluginUrl,
      handle: plugin.value.handle,
    };

    useHead({
      title: pageMeta.value.title,
      meta: [
        {name: 'description', content: pageMeta.value.description},

        {property: 'og:locale', content: 'en_US'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: data.url},
        {property: 'og:title', content: data.name},
        {property: 'og:description', content: data.description},
        {property: 'og:image', content: data.imageUrl},

        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: data.name},
        {name: 'twitter:description', content: data.description},
        {name: 'twitter:image', content: data.imageUrl},
      ],
      script: [
        {
          innerHTML: `{
          "@context": "http://schema.org",
          "@type": "WebApplication",
          "description": "${data.description}",
          "image": {
              "@type": "ImageObject",
              "url": "${data.imageUrl}"
          },
          "inLanguage": "en",
          "name": "${data.name}",
          "url": "${data.url}",
        }`,
          type: 'application/ld+json',
        },
      ],
      link: [
        {
          rel: 'alternate',
          type: 'application/atom+xml',
          title: `${data.name} Releases`,
          href: `https://feeds.craftcms.com/${data.handle}.atom`,
        },
        {rel: 'canonical', href: appUrl + route.fullPath},
      ],
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBeforeRouteLeave((to, from, next) => {
    if (showingScreenshotModal.value) {
      appStore.updateShowingScreenshotModal(false);
    } else {
      next();
    }
  });
</script>

<style lang="scss" scoped>
  /* Plugin Meta */

  ul.plugin-meta {
    li {
      flex-basis: 50%;
    }
  }

  @media only screen and (min-width: 672px) {
    ul.plugin-meta {
      li {
        flex-basis: 33.3333%;
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    ul.plugin-meta {
      li {
        flex-basis: 25%;
      }
    }
  }

  @media only screen and (min-width: 1824px) {
    ul.plugin-meta {
      li {
        flex-basis: 20%;
      }
    }
  }

  /* Plugin description */
  #plugin-description-text > .prose > div > :first-child {
    @apply mt-0;
  }
</style>
